exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._15zbknhQAHE3nbc0_uOMdR{margin-top:35px}.tzjb2EiMRtJsLafJVo60w{margin-left:15px;margin-right:15px}", ""]);

// exports
exports.locals = {
	"conditionTypesInfo": "_15zbknhQAHE3nbc0_uOMdR",
	"hr": "tzjb2EiMRtJsLafJVo60w"
};