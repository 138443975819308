exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3VQOORJSVqL7sRp21OrB1O{width:150px}._3tYJ9GvZLvHk0rGRToMnqN:not(:last-child):after{content:\", \"}", ""]);

// exports
exports.locals = {
	"actions": "_3VQOORJSVqL7sRp21OrB1O",
	"providerOptions": "_3tYJ9GvZLvHk0rGRToMnqN"
};