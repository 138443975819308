exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".g95Sn8lHxAA_SpWHyk37X dt{text-align:left;width:140px}.g95Sn8lHxAA_SpWHyk37X dd{margin-left:160px}", ""]);

// exports
exports.locals = {
	"alertTimeline": "g95Sn8lHxAA_SpWHyk37X"
};