exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tiCXHINw5FhL95elixSWH{vertical-align:baseline}", ""]);

// exports
exports.locals = {
	"deleteButton": "tiCXHINw5FhL95elixSWH"
};