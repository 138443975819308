exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".kBxfMgU-UK22A3gPEOXSr{display:grid;height:100%}._1P9QCAO43Tncg729_NI4--{overflow:auto}._2fPgAu6b0nwZbuyuw6whVF{left:0}@media print{table.messages{font-size:14px!important;min-width:50%;padding-left:0}table.messages th{font-weight:700!important;font-size:inherit!important}table.messages td,table.messages th{border:1px solid #ccc!important;left:0;padding:5px;position:static}}", ""]);

// exports
exports.locals = {
	"container": "kBxfMgU-UK22A3gPEOXSr",
	"scrollContainer": "_1P9QCAO43Tncg729_NI4--",
	"leftAligned": "_2fPgAu6b0nwZbuyuw6whVF"
};