exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1JCP9xI-zM9iz_soNhwBa_{margin-top:-15px;margin-bottom:20px}", ""]);

// exports
exports.locals = {
	"rolesMissingAlert": "_1JCP9xI-zM9iz_soNhwBa_"
};