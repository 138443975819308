exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3KREwA_6Wqfea-a9cr-pse{width:350px}._1TUXzBEADg0b1aivI3u-_6{width:160px}", ""]);

// exports
exports.locals = {
	"name": "_3KREwA_6Wqfea-a9cr-pse",
	"actions": "_1TUXzBEADg0b1aivI3u-_6"
};