exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1FqEawMmDt5xrFobWeKyA4 .label{line-height:2;margin-left:5px;vertical-align:bottom}", ""]);

// exports
exports.locals = {
	"streamTitle": "_1FqEawMmDt5xrFobWeKyA4"
};