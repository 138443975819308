exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".p3GI7BPEZ-jmeN9MEIYe{display:flex;flex-flow:column nowrap;margin-bottom:15px}@media (max-width:768px){.p3GI7BPEZ-jmeN9MEIYe>div{flex-flow:column nowrap}}.p3GI7BPEZ-jmeN9MEIYe>div{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:flex-start;margin-bottom:10px}.p3GI7BPEZ-jmeN9MEIYe .form-group{margin-bottom:0}._33esaouj9IZlvRTR6w8jv8{flex:0 1 45%;margin-right:10px}._33esaouj9IZlvRTR6w8jv8 .form-inline{display:flex;flex-flow:row nowrap}._33esaouj9IZlvRTR6w8jv8 .form-group:first-child{flex:1 100%}._33esaouj9IZlvRTR6w8jv8 .form-group:not(:first-child){margin-right:5px}", ""]);

// exports
exports.locals = {
	"eventsSearchBar": "p3GI7BPEZ-jmeN9MEIYe",
	"searchForm": "_33esaouj9IZlvRTR6w8jv8"
};