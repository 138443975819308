exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UqN96opGVFa0M3dS6Ma8C>a{padding-top:10px!important;padding-bottom:10px!important;display:flex!important;align-items:center;height:50px}._3rns3KK-yW9wtI8PaW1as6{display:grid;grid-template-columns:1fr;grid-template-rows:1fr 1fr;grid-column-gap:0;grid-row-gap:0;height:30px}._1qjKBPJABGueNyK1VWf39m{font-size:11px;line-height:13px;grid-area:2/1/3/2;display:grid;grid-template-columns:1fr 1.75em;grid-template-rows:1fr 1px;grid-column-gap:3px;grid-row-gap:0}._3aLaw8gZJPnnjCGyHFHtCj{grid-area:1/1/2/2}._3aLaw8gZJPnnjCGyHFHtCj:after{content:\" \";min-height:1px;background-color:#d0d0d0;display:block;grid-area:2/1/3/3}._1qjKBPJABGueNyK1VWf39m>span{grid-area:1/1/2/2;text-align:right;padding-left:3px}._1qjKBPJABGueNyK1VWf39m>i{font-weight:400;grid-area:1/2/2/3}@media (max-width:991px){._3rns3KK-yW9wtI8PaW1as6,.UqN96opGVFa0M3dS6Ma8C>a{height:auto;display:block}._3rns3KK-yW9wtI8PaW1as6:before{content:attr(aria-label);font-weight:400}._3rns3KK-yW9wtI8PaW1as6 span,._3rns3KK-yW9wtI8PaW1as6:after{display:none}}", ""]);

// exports
exports.locals = {
	"total-throughput": "UqN96opGVFa0M3dS6Ma8C",
	"total-throughput__content": "_3rns3KK-yW9wtI8PaW1as6",
	"total-throughput__data": "_1qjKBPJABGueNyK1VWf39m",
	"total-throughput__data--in": "_3aLaw8gZJPnnjCGyHFHtCj"
};