exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._25LNHb-RZzyp8MdLHeatM2{margin-top:20px}._3FbceraKBVw414DxzmZmRC{margin-top:10px}._3-u94u1Yj9MwBlLxY4hSdY{white-space:pre-wrap}.panel-body h3{margin-bottom:5px}._2NxOSHf3OnVOqT8a-8IH_o{max-height:400px}._15BhqEibx0LiLM3T7MJnSG{font-family:monospace}._1vRO3Ss6daqPPm-5lSP-kR{list-style-type:disc;padding-left:20px;padding-top:20px}._3GV6poMAy3L4qMkGzMfwFi{width:120px}", ""]);

// exports
exports.locals = {
	"marginQuickReferenceText": "_25LNHb-RZzyp8MdLHeatM2",
	"marginTab": "_3FbceraKBVw414DxzmZmRC",
	"exampleFunction": "_3-u94u1Yj9MwBlLxY4hSdY",
	"tableMaxHeight": "_2NxOSHf3OnVOqT8a-8IH_o",
	"monoSpaceFont": "_15BhqEibx0LiLM3T7MJnSG",
	"ulStyle": "_1vRO3Ss6daqPPm-5lSP-kR",
	"actionsColumn": "_3GV6poMAy3L4qMkGzMfwFi"
};