exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1sutHEzFTcXWlc6XqMdYjs{margin-bottom:20px}._3lb9AZDjRck6-Tr4VJxaEJ{table-layout:fixed}", ""]);

// exports
exports.locals = {
	"field": "_1sutHEzFTcXWlc6XqMdYjs",
	"fixedTable": "_3lb9AZDjRck6-Tr4VJxaEJ"
};