exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3lw2dcaCQ4HUA9Bt-8fUch span:not(:last-child):after{content:\", \"}", ""]);

// exports
exports.locals = {
	"streamList": "_3lw2dcaCQ4HUA9Bt-8fUch"
};