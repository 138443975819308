exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3xG0rHyIt5lduV_7bvnbN7{margin-bottom:10px}.Go4v2ykT7SDOCS0x5lDWT{height:2rem;width:2rem;border-radius:4px;border:1px solid rgba(0,126,255,.24)}.u90x-VNVlefhq1kwR_bGH{margin-top:5px;display:flex}.u90x-VNVlefhq1kwR_bGH *{margin-left:.2rem;margin-right:.2rem}.u90x-VNVlefhq1kwR_bGH div{margin-right:.4rem}", ""]);

// exports
exports.locals = {
	"headline": "_3xG0rHyIt5lduV_7bvnbN7",
	"colorElement": "Go4v2ykT7SDOCS0x5lDWT",
	"highlightingRuleBlock": "u90x-VNVlefhq1kwR_bGH"
};