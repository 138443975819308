exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._38zpKcAj-On8b-e9iSP0qe{background-color:#dff0d8;color:#3c763d;display:table}._1Ypq74_EEFkMwBBwgfoSXK{background-color:#f2dede;color:#a94442;display:table;text-decoration:line-through}._2Y-9pJdk9KDpjwfh2xPew_{background-color:#d9edf7;color:#31708f;display:table;text-decoration:line-through}._1UifGocu4g3rN0prvjQa8{font-family:monospace}", ""]);

// exports
exports.locals = {
	"added-fields": "_38zpKcAj-On8b-e9iSP0qe",
	"removed-fields": "_1Ypq74_EEFkMwBBwgfoSXK",
	"mutated-fields": "_2Y-9pJdk9KDpjwfh2xPew_",
	"field-value": "_1UifGocu4g3rN0prvjQa8"
};