exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1zPBgIECxuTPtWfIKEy_pW{float:right;margin-top:10px}._1evWyT2yAMsZGXzvKyo46l{margin-top:35px}", ""]);

// exports
exports.locals = {
	"manageNotifications": "_1zPBgIECxuTPtWfIKEy_pW",
	"notificationSettings": "_1evWyT2yAMsZGXzvKyo46l"
};