import React from 'react';

import { Icon } from 'components/common';
import DocumentationLink from './DocumentationLink';
import DocsHelper from '../../util/DocsHelper';

const SupportSources = () => (
  <div className="support-sources">
    <h2>Need help?</h2>
    <p>
      Do not hesitate to Call 844-AIS-LABS or Email support@aislabs.com if your questions are not answered in the{' '}
      <DocumentationLink page={DocsHelper.PAGES.WELCOME} text="documentation" />.
    </p>

  </div>
);

SupportSources.propTypes = {};

export default SupportSources;
