exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2RReCI5doWsql2r5ISauB-{font-family:monospace;font-size:12px;overflow-wrap:break-word;white-space:pre-wrap}", ""]);

// exports
exports.locals = {
	"bodyPreview": "_2RReCI5doWsql2r5ISauB-"
};