import React from 'react';

class QuickValuesHistogramWidgetCreateConfiguration extends React.Component {
  static initialConfiguration = {};

  render() {
    return null;
  }
}

export default QuickValuesHistogramWidgetCreateConfiguration;
