exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Av-URosScR8mPL_KeNzFe dl{margin-top:10px}._3Av-URosScR8mPL_KeNzFe dt{float:left;clear:left}._3Av-URosScR8mPL_KeNzFe dd{margin-left:150px;word-wrap:break-word}._3Av-URosScR8mPL_KeNzFe dd:not(:last-child){border-bottom:1px solid #ececec;margin-bottom:5px;padding-bottom:5px}", ""]);

// exports
exports.locals = {
	"config": "_3Av-URosScR8mPL_KeNzFe"
};