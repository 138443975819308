exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1yd1CsQNM5SYCnkAfa3ipv{margin-right:5px;margin-left:2px}._1pVJPUdHOb45SK5hc0hXTn{margin-top:10px}._1pVJPUdHOb45SK5hc0hXTn dt{float:left;clear:left}._1pVJPUdHOb45SK5hc0hXTn dd{margin-left:160px}._2ZzLPBkvPLC0JBZ3GIX4UQ{margin-top:10px}._375TbNmUzMbpdN35jJ1veU{opacity:.5;z-index:20}", ""]);

// exports
exports.locals = {
	"sidecarOs": "_1yd1CsQNM5SYCnkAfa3ipv",
	"deflist": "_1pVJPUdHOb45SK5hc0hXTn",
	"topMargin": "_2ZzLPBkvPLC0JBZ3GIX4UQ",
	"greyedOut": "_375TbNmUzMbpdN35jJ1veU"
};