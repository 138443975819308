import React from 'react';
import AppRouter from 'routing/AppRouter';

class LoggedInPage extends React.Component {
  render() {
    return <AppRouter />;
  }
}

export default LoggedInPage;
