exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._24j1zMq_c5AJSAzJruS7eT{margin-left:auto;margin-right:auto;text-align:left}._24j1zMq_c5AJSAzJruS7eT dt{font-size:1.2em;overflow:auto}._1kunWG6nLWADlg-rlIbZc{background-color:#f5f5f5}._1PwmZrHyUpgohZZImuNKMp{font-weight:400}", ""]);

// exports
exports.locals = {
	"errorMessage": "_24j1zMq_c5AJSAzJruS7eT",
	"greyBackground": "_1kunWG6nLWADlg-rlIbZc",
	"toggleDetails": "_1PwmZrHyUpgohZZImuNKMp"
};