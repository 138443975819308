exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Sgvgq03Fbtxaews5JjAB7{white-space:nowrap}", ""]);

// exports
exports.locals = {
	"indicator": "_3Sgvgq03Fbtxaews5JjAB7"
};