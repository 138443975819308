exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".popover._3sRe_wQ02Pd9knIm1N-b3T{max-width:500px}", ""]);

// exports
exports.locals = {
	"popoverWide": "_3sRe_wQ02Pd9knIm1N-b3T"
};