exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3MvbuZ8PB0ortBeEQkHScR{vertical-align:middle}._1M6pGp_EYuTP7hsn4OPVat{font-size:1em}._1T5DmgEa2IdNf4gl00DRBn{font-size:.75em}._2sgSNHReZBrrUqg6BPRTkt{font-size:.5em}", ""]);

// exports
exports.locals = {
	"colorLabel": "_3MvbuZ8PB0ortBeEQkHScR",
	"normal": "_1M6pGp_EYuTP7hsn4OPVat",
	"small": "_1T5DmgEa2IdNf4gl00DRBn",
	"xsmall": "_2sgSNHReZBrrUqg6BPRTkt"
};