exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._22-cQTgLvYcXuOEvjevsG{opacity:.3;position:relative;top:3px}", ""]);

// exports
exports.locals = {
	"button": "_22-cQTgLvYcXuOEvjevsG"
};