exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PpHCh7IoFl4CRkFV55NLr{height:160px;max-height:160px;overflow:hidden;overflow-y:scroll;padding-left:0;border-bottom:1px solid #ddd}._10xUP19hn9AcGI3Zn_QnTG{margin-bottom:0}._3XACxXVlgUgDyKtC1XF2Fq{position:absolute;top:7px;right:7px}._1eISBHB6XPPAMWV2quKARp{text-overflow:ellipsis;white-space:nowrap;overflow:hidden;display:block}", ""]);

// exports
exports.locals = {
	"resultList": "_3PpHCh7IoFl4CRkFV55NLr",
	"filterFormGroup": "_10xUP19hn9AcGI3Zn_QnTG",
	"addButton": "_3XACxXVlgUgDyKtC1XF2Fq",
	"patternDisplay": "_1eISBHB6XPPAMWV2quKARp"
};