exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3pRVc0w2SR47jF2esji1A{color:#aaa}", ""]);

// exports
exports.locals = {
	"timezoneInfo": "_3pRVc0w2SR47jF2esji1A"
};