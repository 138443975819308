exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Yp70UWnFp2Bv7r6SDl4ov{margin:auto}", ""]);

// exports
exports.locals = {
	"pager": "_1Yp70UWnFp2Bv7r6SDl4ov"
};