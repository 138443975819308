exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1hgvpAgc47JXlGy0WVZL6Y{height:100%;text-align:center;padding-bottom:10px}._1vguz3a6jhu3pnfmzxrDEw{height:100%;width:100%}._1Xa3N4I8bDpLyD8cM8wdZ{dominant-baseline:central;text-anchor:middle}", ""]);

// exports
exports.locals = {
	"container": "_1hgvpAgc47JXlGy0WVZL6Y",
	"number": "_1vguz3a6jhu3pnfmzxrDEw",
	"value": "_1Xa3N4I8bDpLyD8cM8wdZ"
};