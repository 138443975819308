exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2mCZPAGtCbXhAMQODxsaxA a{display:block;padding:3px 20px;clear:both;font-weight:400;line-height:1.42857143;color:#666;white-space:nowrap}._2mCZPAGtCbXhAMQODxsaxA a:focus,._2mCZPAGtCbXhAMQODxsaxA a:hover{text-decoration:none;color:#595959;background-color:#f5f5f5}", ""]);

// exports
exports.locals = {
	"menuItem": "_2mCZPAGtCbXhAMQODxsaxA"
};