exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".VaBZyqgObt_Yviuulmpvo.unlocked .react-draggable{cursor:move}.VaBZyqgObt_Yviuulmpvo.locked .react-resizable-handle{display:none}.VaBZyqgObt_Yviuulmpvo .react-grid-placeholder{background:#16ace3}.VaBZyqgObt_Yviuulmpvo .actions{cursor:default}", ""]);

// exports
exports.locals = {
	"reactGridLayout": "VaBZyqgObt_Yviuulmpvo"
};