exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1rcdikuS1k2z55DW2_AURV{table-layout:fixed;overflow-wrap:break-word}", ""]);

// exports
exports.locals = {
	"fixedTable": "_1rcdikuS1k2z55DW2_AURV"
};