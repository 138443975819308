exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3HrW10n1kzGWl5PDY4B2uD{background-color:#fff;border:1px solid #d1d1d1;z-index:auto}", ""]);

// exports
exports.locals = {
	"widgetContainer": "_3HrW10n1kzGWl5PDY4B2uD"
};