exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2qAX8L-lA-dhBBWkPda3Jv dl dl{padding-left:10px;margin-bottom:5px}", ""]);

// exports
exports.locals = {
	"eventSummary": "_2qAX8L-lA-dhBBWkPda3Jv"
};