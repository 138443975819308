exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PEhNfSXMYZG6wCVpnP2aU tbody{max-height:300px;overflow:hidden;overflow-y:auto;display:block}.PEhNfSXMYZG6wCVpnP2aU tbody tr,.PEhNfSXMYZG6wCVpnP2aU thead{display:table;width:100%;table-layout:fixed}._1mebSNHsy14M59p-TcgFW6{word-wrap:break-word}.AMcHIBXI1NlARbs7HyFbM{color:rgba(158,31,99,.43)}", ""]);

// exports
exports.locals = {
	"scrollable": "PEhNfSXMYZG6wCVpnP2aU",
	"bigColumns": "_1mebSNHsy14M59p-TcgFW6",
	"contentPackEntity": "AMcHIBXI1NlARbs7HyFbM"
};