exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._21LEXZU8kxIAw8urd7r2pZ tbody{max-height:300px;overflow:hidden;overflow-y:auto;display:block}._21LEXZU8kxIAw8urd7r2pZ tbody tr,._21LEXZU8kxIAw8urd7r2pZ thead{display:table;width:100%;table-layout:fixed}._3A5kR_mkBGnqELaCufAMfD{word-wrap:break-word}", ""]);

// exports
exports.locals = {
	"scrollable": "_21LEXZU8kxIAw8urd7r2pZ",
	"bigColumns": "_3A5kR_mkBGnqELaCufAMfD"
};