exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AR0-U4tixQDL1wBRuPHtT{margin-left:0;padding-left:0}", ""]);

// exports
exports.locals = {
	"enterprisePlugins": "AR0-U4tixQDL1wBRuPHtT"
};