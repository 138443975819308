exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2z2JFU0wYM-h6Fzzv56zsv{opacity:.7}", ""]);

// exports
exports.locals = {
	"fieldTypeIcon": "_2z2JFU0wYM-h6Fzzv56zsv"
};