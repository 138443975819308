exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1glWojMayeh_ollzGHnL8K{color:rgba(158,31,99,.43)}", ""]);

// exports
exports.locals = {
	"contentPackEntity": "_1glWojMayeh_ollzGHnL8K"
};