exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2mHWy-VaLgIlqVN58WFlSr{max-width:400px}._1jmVzNrm3Ff2ywVsgBUxAJ{margin-right:5px}", ""]);

// exports
exports.locals = {
	"overlay": "_2mHWy-VaLgIlqVN58WFlSr",
	"trigger": "_1jmVzNrm3Ff2ywVsgBUxAJ"
};