exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._29s3VW7dYSx7WzLkNezdzw{margin-left:4px;background:#8dc63f}._1cNV4zZ838js4RtnIql2dv{margin-left:4px;background:#9e1f63}._2TuPSFrRWJoEdkZvO6os2a{margin-left:4px;background:#f89406}._2sVWfxKNFJ2aa0MVQv8Am6{margin-left:4px;background:#a94442}", ""]);

// exports
exports.locals = {
	"installed": "_29s3VW7dYSx7WzLkNezdzw",
	"updatable": "_1cNV4zZ838js4RtnIql2dv",
	"edited": "_2TuPSFrRWJoEdkZvO6os2a",
	"error": "_2sVWfxKNFJ2aa0MVQv8Am6"
};