exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".najT6ar9WAygmemWcMjn3 dt{width:50px;text-align:left}.najT6ar9WAygmemWcMjn3 dd{margin-left:70px}", ""]);

// exports
exports.locals = {
	"alertDescription": "najT6ar9WAygmemWcMjn3"
};