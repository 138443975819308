exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2bniiYkxoVAuST2IQvmwpY{margin:5px}", ""]);

// exports
exports.locals = {
	"button": "_2bniiYkxoVAuST2IQvmwpY"
};