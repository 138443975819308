exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2RqEGG3NUbQVdnGYtcP-x9{padding-left:15px}", ""]);

// exports
exports.locals = {
	"optionsFieldSet": "_2RqEGG3NUbQVdnGYtcP-x9"
};