exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3qUqsNh0EsjXCcw9GgrMdk{font-size:85%}", ""]);

// exports
exports.locals = {
	"alertStatusLabel": "_3qUqsNh0EsjXCcw9GgrMdk"
};