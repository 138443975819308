exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wmiaxY9zpQogM3Z1zlKLr .panel-body{padding:20px 10px}.wmiaxY9zpQogM3Z1zlKLr table{table-layout:fixed;word-wrap:break-word}", ""]);

// exports
exports.locals = {
	"filterPreview": "wmiaxY9zpQogM3Z1zlKLr"
};