exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1nMuMHVw4CwQEsDB2ZIcxA{font-size:12px}._1jzOFTX6rcaHioqDrbefXj{color:#72a230}._2SzIWOlWbzCZsyFhwXvIMJ{margin-right:5px;margin-bottom:5px;display:inline-block;line-height:15px}.kC2umW87uww30Z_H44wv1{max-width:300px}._31-RlLhjKTlFeuqwxU3Z9Q{width:180px;max-width:180px}._1z326CnBfOJTcXiFkdkWyZ{cursor:help}._3jtB0mSKD0gEANdY1uXjBe{cursor:help;padding:0 0 0 2px;display:inline-flex}._2aWE0aeopfmpmaZeN6lQOS{min-width:auto}#user-list{overflow-x:visible}", ""]);

// exports
exports.locals = {
	"sessionBadgeDetails": "_1nMuMHVw4CwQEsDB2ZIcxA",
	"activeSession": "_1jzOFTX6rcaHioqDrbefXj",
	"roleBadgeFixes": "_2SzIWOlWbzCZsyFhwXvIMJ",
	"limitedWide": "kC2umW87uww30Z_H44wv1",
	"actions": "_31-RlLhjKTlFeuqwxU3Z9Q",
	"help": "_1z326CnBfOJTcXiFkdkWyZ",
	"helpHeaderRow": "_3jtB0mSKD0gEANdY1uXjBe",
	"moreActions": "_2aWE0aeopfmpmaZeN6lQOS"
};