exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PTEviBlEnlV1SQ9Pb6pk9{width:180px}._2sAp_3B6GSFMYs1dP9bnDO{display:inline-block}._2npf4qxAkgBn0La7MBFRDe{margin-top:15px}._3pG-HST9T0lNmqQ0ZHOW3b .pagination{margin:5px 0}", ""]);

// exports
exports.locals = {
	"actionsColumn": "_3PTEviBlEnlV1SQ9Pb6pk9",
	"inline": "_2sAp_3B6GSFMYs1dP9bnDO",
	"configurationTable": "_2npf4qxAkgBn0La7MBFRDe",
	"configurationRow": "_3pG-HST9T0lNmqQ0ZHOW3b"
};