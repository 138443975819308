exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1QKX45vsfYHFmZvJQYO5pI{margin-bottom:15px;overflow-wrap:break-word}", ""]);

// exports
exports.locals = {
	"legacyNotificationAlert": "_1QKX45vsfYHFmZvJQYO5pI"
};