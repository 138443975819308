exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3BVT5shdaxvQrky8SMkCc_{margin-top:10px}._1VPlUNTwh61DLucOBTqFWk,._3iNopBA55glkEzeSy4fV0W{max-height:400px;margin-top:10px}._3iNopBA55glkEzeSy4fV0W{overflow:auto}", ""]);

// exports
exports.locals = {
	"optionsFormWrapper": "_3BVT5shdaxvQrky8SMkCc_",
	"spinnerWrapper": "_1VPlUNTwh61DLucOBTqFWk",
	"visualizationWrapper": "_3iNopBA55glkEzeSy4fV0W"
};