exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2tv64tb7N0ZtlkIZTxbUWW{margin-left:8px;margin-right:8px;padding-left:8px;padding-right:8px}", ""]);

// exports
exports.locals = {
	"leftRow": "_2tv64tb7N0ZtlkIZTxbUWW"
};