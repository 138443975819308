exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1u3SPkvs7usmM3PEpkznDK{margin-top:10px;margin-bottom:10px}.C6s2uOGpn1vtgtH6hQLtI{table-layout:fixed}._24BkIDwwyfZ6Q9Ub2n2YYV.alert{margin-bottom:10px}._24BkIDwwyfZ6Q9Ub2n2YYV h4{font-size:16px;margin-bottom:.5em}._24BkIDwwyfZ6Q9Ub2n2YYV ul{list-style:disc;margin:.5em 0}", ""]);

// exports
exports.locals = {
	"title": "_1u3SPkvs7usmM3PEpkznDK",
	"fixedTable": "C6s2uOGpn1vtgtH6hQLtI",
	"validationSummary": "_24BkIDwwyfZ6Q9Ub2n2YYV"
};