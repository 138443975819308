exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3wZo8zSYOErM5bGrAtbbf{cursor:pointer}.eCGaBNt2pGCu1kqdJtVWf{width:300px}.mUZc-dWn26sYVZUvMpHFx{margin-top:5px}._2EOoEyuI0H-ZTgpCL6FX02{margin-top:10px}.MluxsFKnKF10ANeqGmdGk{white-space:pre-wrap}._16ByHLUH0BvD8tS76Xx1gu{width:1%}", ""]);

// exports
exports.locals = {
	"clickableRow": "_3wZo8zSYOErM5bGrAtbbf",
	"functionTableCell": "eCGaBNt2pGCu1kqdJtVWf",
	"marginQuickReferenceText": "mUZc-dWn26sYVZUvMpHFx",
	"marginTab": "_2EOoEyuI0H-ZTgpCL6FX02",
	"exampleFunction": "MluxsFKnKF10ANeqGmdGk",
	"adjustedTableCellWidth": "_16ByHLUH0BvD8tS76Xx1gu"
};